import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom"
import Header2 from "../components/Header2";
import GCard from "../components/GCARD";
import GFooter from "../components/GFOOTER"
import { useEffect } from "react";
import { setLogin } from "../redux/state";
import { isTokenExpired } from "../utility/CheckToken";

const WishList = () => {
  const user = useSelector((state) => state.user);
  const wishList = useSelector((state) => state.user?.wishList);
  //console.log("WishList", wishList);
  const token = useSelector((state) => state.token);
  const model = useSelector((state) => state.modelType);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (token && isTokenExpired(token)) {
     // console.log("Token expired");
      dispatch(setLogin({
        user: null,
        token: null,
        modelType: null
      }));
      console.log('Session expired. Please log in again.');
      model === "host" ? navigate('/host_login') : navigate('/login');
      return;
    }
  }, [token, model, dispatch, navigate]);

  return (
    <>
      <Header2 />
      {user && model === "user" ?
        <>
          <div className="bg-slate-100">
            <h1 className="text-center font-bold text-2xl sm:text-3xl py-20 mx-auto text-slate-800">Your Wish List</h1>
            {wishList?.data?.length === 0
              ?
              <div className="h-[65vh] flex flex-col  justify-center items-center font-bold text-xl mx-auto text-slate-700 pb-10 tracking-wider">No properties in your Wishlist!<br /> <Link to="/all-listings" className={`m-1 p-1 text-black text-lg animate-pulse`}>Add now</Link>
              </div>
              :
              <div className="flex flex-wrap justify-center items-center gap-8 px-auto md:px-[100px] pb-[120px]">
                {wishList?.data?.map(
                  ({
                    _id,
                    creator,
                    listingPhotoPaths,
                    city,
                    province,
                    country,
                    category,
                    type,
                    price,
                    booking = false,
                  }) => (
                    <GCard
                      listingId={_id}
                      creator={creator}
                      listingPhotoPaths={listingPhotoPaths}
                      city={city}
                      province={province}
                      country={country}
                      category={category}
                      type={type}
                      price={price}
                      booking={booking}
                    />
                  )
                )}
              </div>}
          </div> </> 
          :
        <>
          <div className="h-screen  bg-gradient-to-r from-slate-300 to-white flex flex-col justify-center items-center gap-5 tracking-wider text-lg">
            <div className='text-base sm:text-xl flex gap-x-6  text-slate-700 font-bold  px-4 sm:px-auto py-4'> You need to be logged in to access the Wishlist page.</div>
            <Link to="/login" className='text-lg font-semibold text-blue-700 px-1 '> Login Here</Link>
          </div> 
        </>
      }

      <GFooter />
    </>
  );
};

export default WishList;